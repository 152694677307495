<template>
  <div></div>
</template>

<script setup name="GlobalMessage">
import { ElNotification, ElMessageBox } from "element-plus";
import { Upload } from "@element-plus/icons-vue";
import XJWarning from "./xjWarning";
import { useWsStore } from "@/store/modules/websocket";
import { h, onMounted } from "vue";
import { headers } from "@/config/constant";
import { relieveAlarm } from "@/api/xunjian";
import { message } from "@/utils/message";
const wsStore = useWsStore();

// 告警提示-start
let currentNotifyId = null;
let warnNotifyInstance = null;
const getWarningMessage = (warning = {}) => {
  warnNotifyInstance && warnNotifyInstance.close();
  warnNotifyInstance = ElNotification({
    dangerouslyUseHTMLString: true,
    // message: 'dafjdjfjdkajf',
    message: h(XJWarning, {
      class: "xj-warning-content",
      warning: warning,
      onCancel: () => {
        unbindConfirm(warning);
      },
      onConfirm: () => {
        warnNotifyInstance.close();
        window.open(
          headers["xunjian"] + "/alarmRecord/list?status=0",
          "_blank"
        );
      },
    }),
    onClose() {
      sendClearWarning();
    },
    class: "xj-warning-box",
    duration: 0,
  });
};

const sendUnbind = (warning) => {
  let { id } = warning;
  return relieveAlarm({ ids: [id], isAll: 0 }).then((res) => {
    message("解除成功", { type: "success" });
  });
};
const unbindConfirm = (warning) => {
  ElMessageBox.confirm("确定要解绑该条警报？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    beforeClose: (action, instance, done) => {
      if (action === "confirm") {
        sendUnbind(warning);
        done();
        warnNotifyInstance.close();
      } else {
        done();
      }
    },
  })
    .then(() => {})
    .catch(() => {});
};
const sendClearWarning = () => {
  let str = JSON.stringify({
    type: "RELEASE_LAST_WARNING",
  });
  wsStore.websocket.send(str);
};
// getWarningMessage();
// 告警提示-end
const watchMessage = () => {
  wsStore.websocket.addEventListener("message", (e) => {
    let res = JSON.parse(e.data);
    let { type, data } = res;
    if (type === "LAST_WARNING") {
      if (!currentNotifyId && data && data.id) {
        currentNotifyId = data.id;
        getWarningMessage(data);
      }
    }
  });
};
watchMessage();
</script>
<style lang="scss">
.el-notification.xj-warning-box {
  // width: 500px;
  width: initial;
}
</style>
